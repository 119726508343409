<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-card flat class="card-datatable">
      <v-card-text>
        <v-row>
          <v-col cols="6" md="3" class="pl-3" >
            <v-autocomplete
              label="Filter By Year"
              outlined
              dense
              v-model="yearFilter"
              :items="yearList"
              class="formFields"
              color="#7253CF"
              :disabled="loading"
              @change="getStudentData()"
            >
            </v-autocomplete>
          </v-col>
            <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            class="d-flex px-2 text-right"
            cols="5"
            md="3"
            style="margin-top: -7px;"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="285px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :disabled="studentDataTable.formLoading"
                  v-model="dateRangeText"
                  label="Filter by Date"
                  prepend-inner-icon="mdi-calendar-blank"
                  clearable
                  v-bind="attrs"
                  outlined
                  v-on="on"
                  dense
                  height="43px"
                  color="#2C1963"
                  style="font-size: 15px; margin: 5px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                range
                no-title
                @input="filterDate(dates)"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
           <v-col
            :cols="panel === 'district' ? '2' : '6'"
            v-if="layout === 'CampaignDashboardLayout' && $vuetify.breakpoint.xsOnly"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class"   v-if="this.$route.matched[0].path.substring(1) !== 'teacher'">
                  <v-list-item   @click="exportStudent">
                    <v-list-item-title> Export Registration Data </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group active-class="active-class"    v-if="
                this.$route.matched[0].path.substring(1) === 'school' ||
                this.$route.matched[0].path.substring(1) === 'teacher'
              ">
                  <v-list-item @click="exportStudentData">
                    <v-list-item-title> Export Student Data </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
                 <v-list-item-group active-class="active-class"   v-if="
                  (this.$route.matched[0].path.substring(1) === 'school' ||
                    this.$route.matched[0].path.substring(1) === 'teacher')
              ">
                  <v-list-item  @click="
               toggleAddEditViewStudentModal({ show: true, type: 'add' })
              ">
                    <v-list-item-title>Add Student</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex" cols="12" md="3">
            <v-text-field
              outlined
              dense
              v-model="studentDataTable[$API_KEYS.SEARCH]"
              label="Search by Name, Parent, School or Class Name"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              height="43px"
              style="margin: 5px"
              :disabled="studentDataTable.loading"
            >
            </v-text-field>
          </v-col>
          <!--<v-col
            v-if="$vuetify.breakpoint.xsOnly"
            class=""
            align-self="center"
            cols="1"
          >
            
          </v-col> -->
          <v-col
           class="d-flex"
            :cols="panel === 'district' ? '2' : '9'"
            v-if="layout === 'CampaignDashboardLayout' && $vuetify.breakpoint.smAndUp"
          >
            <v-btn
              text
              style="
                border: 1px solid #38227a;
                border-radius: 10px;
                width: 200px;
                margin: 5px;
              "
              class="text-capitalize mr-2 px-12 ml-3"
              height="45px"
              @click="exportStudent"
              :loading="btnLoading"
              v-if="this.$route.matched[0].path.substring(1) !== 'teacher'"
            >
              <span style="color: #38227a">Export Registration Data</span>
            </v-btn>
            <v-btn
              text
              style="
                border: 1px solid #38227a;
                border-radius: 10px;
                width: 200px;
                margin: 5px;
              "
              class="text-capitalize mr-2 px-12"
              height="45px"
              @click="exportStudentData"
              :loading="btnLoading1"
              v-if="
                this.$route.matched[0].path.substring(1) === 'school' ||
                this.$route.matched[0].path.substring(1) === 'teacher'
              "
            >
              <span style="color: #38227a">Export Student Data</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              @click="
                toggleAddEditViewStudentModal({ show: true, type: 'add' })
              "
              class="px-11 text-capitalize ml-3"
              height="45px"
              style="border-radius: 10px; margin: 5px;width: 15%;"
              v-if="
                  (this.$route.matched[0].path.substring(1) === 'school' ||
                    this.$route.matched[0].path.substring(1) === 'teacher')
              "
            >
              <span>Add Student</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div">
      <v-data-table
        fixed-header
        :options.sync="dataOptions"
        hide-default-footer
        :headers="studentDataTable.headers"
        :items="studentDataTable.items"
        :loading="studentDataTable.loading"
        :items-per-page="
          studentDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          studentDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="row">
          <tr class="text-flex" style="font-size: 14px">
            <td class="text-center row-item py-4 px-0 pl-2">
              <div style="font-weight: 600">
                {{ row.item.student_first_name }}
                {{ row.item.student_last_name }}
              </div>
              <div style="font-weight: 600; font-size: 13px; color: #777777">
                joined on <br />
                <span>{{ row.item.joined_on }}</span>
              </div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600; color: #1d1d1d">
                {{ row.item.parent_name }}
              </div>
              <div style="color: #777777">{{ row.item.parent_email }}</div>
              <!-- <div style="color: #777777">{{ row.item.campaign_district }}</div> -->
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600; color: #1d1d1d">
                {{ row.item.school_name }}
              </div>
              <div style="font-weight: 600; color: #1d1d1d">
                {{ row.item.district_name }}
              </div>
              <div style="font-size: 13px; color: #777777">
                {{ row.item.campaign_id }}
              </div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600; color: #1d1d1d">
                {{ row.item.grade_teacher }}
              </div>
              <div style="color: #777777">Grade {{ row.item.grade_title }}</div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600">
                ${{ row.item.orignal_fund_raised }}/<span
                  style="color: #777777; font-weight: 400"
                  >${{ row.item.student_goal }}</span
                >
              </div>
              <div style="color: #067605; weight: 700">
                {{ row.item.fund_raised_percentage }}%
              </div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600;color:green" v-if="row.item.is_challenge_completed==true">Completed</div>
               <div style="font-weight: 600;color:red" v-else>Incomplete</div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600">{{row.item.distinct_shares}}</div>
            </td>
            <td class="text-center row-item py-4">
              <v-row class="justify-center">
                <v-col class="pa-0 text-center" cols="12" xl="8">
                  <v-chip
                    @click="viewPublicDonationPageHandler(row.item)"
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                      <span>Public Page</span>
                    </v-tooltip>
                  </v-chip>

                  <v-chip
                    @click="
                      toggleAddEditViewStudentModal({
                        show: true,
                        type: 'edit',
                        id: row.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-chip>
                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        color="#F2F2F2"
                        class="actionColumn-btn"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="#6B6B6B" size="25"
                          >mdi-dots-horizontal</v-icon
                        >
                      </v-chip>
                    </template>
                    <v-list class="menu-list py-0">
                      <v-list-item-group class="py-0">
                        <v-list-item
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                          @click="
                            toggleDeleteStudentModal({
                              Delete: true,
                              id: row.item.id,
                            })
                          "
                        >
                          <v-icon left small>mdi-delete</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Delete
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                          @click="resendLink(row.item)"
                        >
                          <v-icon left small>mdi-email-send</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Send Reset Password Link
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                          v-if="
                            $route.matched[0].path.substring(1) === 'school'
                          "
                          @click="updateVideo(row.item)"
                        >
                          <v-icon left small>mdi-video</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Update Student Video
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="studentDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPage"
                      hide-details
                      v-model="
                        studentDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{
                      studentDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      studentDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      studentDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      studentDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="studentDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    studentDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  studentDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  studentDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  studentDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  studentDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <add-edit-student-modal @reload="getStudentData"></add-edit-student-modal>
    <delete-student-modal @reload="getStudentData"></delete-student-modal>
    <video-update-modal></video-update-modal>
    <br />
  </div>
</template>
<script>
import moment from "moment";
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  DATA,
  SORTING_KEY,
  STUDENT_LIST,
} from "@/constants/APIKeys";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_ADMIN_STUDENT_MANAGEMENT_LIST,
  API_EXPORT_STUDENT,
  API_EXPORT_STUDENT_DATA,
  API_GET_FISCAL_YEAR_RANGE,
} from "@/constants/APIUrls";
import { API_COMMON_URL } from "@/constants/urls";
import _ from "lodash";
import authToken from "@/common/authToken";
import Helper from "@/helper";
export default {
  name: "StudentManagement",
  components: {
    AddEditStudentModal: () =>
      import("./AddEditStudentModal/AddEditStudentModal.vue"),
    DeleteStudentModal: () =>
      import("./DeleteStudentConfirmation/DeleteStudentConfirmation.vue"),
    VideoUpdateModal: () =>
      import(
        "../Dashboard/PeTeacherDashboard/AddRecordModal/AddRecordModal.vue"
      ),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      yearList: [],
      yearFilter: "",
      dates: [],
      menu: false,
      dataOptions: { sortBy: [], sortDesc: [] },
      start_date: "",
      end_date: "",
      btnLoading: false,
      btnLoading1: false,
      studentDataTable: {
        loading: false,
        headers: [
          {
            sortable: true,
            text: "Student",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
            value: "name",
          },
          {
            sortable: true,
            text: "Parent",
            align: "center",
            value: "parent",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "Campaign",
            align: "center",
            value: "campaign",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "Class",
            align: "center",
            value: "grade",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: true,
            text: "Fund Raised",
            value: "total_fund_raised",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: false,
            text: "Wizfit Challenge Status",
            value: "total_videos_watched",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            sortable: true,
            text: "# of Shares",
            value: "total_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "16%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    layout() {
      return this.$route.meta.layout;
    },
    panel() {
      return this.$route.matched[0].path.substring(1);
    },
    dateRangeText: {
      get() {
        if (this.dates) {
          if (this.dates.length > 1) {
            let date = [
              moment(this.dates[0]).format("MM-DD-YYYY"),
              moment(this.dates[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates.join("  -  ");
      },
      set(newDates) {
        this.dates = newDates || [];
        return this.dates.length > 1 ? this.dates.join("  -  ") : "";
      },
    },
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "studentDataTable.page_info.page_number": function () {
      this.debounceGetStudentManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "studentDataTable.page_info.detail_count": function () {
      this.getStudentData();
    },
    //Watcher for detecting change in SEARCH
    "studentDataTable.search": function () {
      this.debounceGetStudentManagementData();
    },
    //Watcher for detecting Change in filter by date
    dates: function () {
      if (this.dates.length !== 1) {
        this.debounceGetStudentManagementData();
      }
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.studentDataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getStudentData();
      } else {
        this.getStudentData[SORTING_KEY] = "";
        this.getStudentData();
      }
    },
  },
  methods: {
    ...mapActions({
      // teacher Modal
      toggleAddEditViewStudentModal: "studentManagement/toggleModal",
      toggleDeleteStudentModal: "studentManagement/toggleDeleteModal",
      showToast: "snackBar/showToast",
      toggleAddRecordModal: "peTeacher/toggleModal",
    }),
    filterDate(dates) {
      if (dates.length > 1) {
        this.$refs.menu.save(dates);
      }
    },
    getStudentData() {
      const self = this;
      self.studentDataTable.loading = true;
      console.log("in get student data");
      console.log(this.layout);

      const successHandler = (res) => {
        console.log(
          "ITEMS_PER_PAGE: ",
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE],
          "PAGE_NUMBER: ",
          res[DATA][PAGE_INFO][PAGE_NUMBER],
          "TOTAL_PAGE: ",
          res[DATA][PAGE_INFO][TOTAL_PAGE],
          "STUDENT_LIST: ",
          res[DATA][STUDENT_LIST],
          res.data
        );
        self.studentDataTable.items = res[DATA][STUDENT_LIST];
        self.studentDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.studentDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.studentDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];

        self.studentDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res, "failed");
        self.studentDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.studentDataTable[SEARCH];
      if (this.studentDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.studentDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.studentDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.studentDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      dataTableParams[SORTING_KEY] = this.studentDataTable[SORTING_KEY];
      if (this.yearFilter) {
        dataTableParams.fiscal_year = this.yearFilter;
      }
      if (this.dates) {
        if (this.dates.length == 2) {
          let filter = {
            start_date: moment(this.dates[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates[1]).format("MM-DD-YYYY"),
          };
          dataTableParams["filter"] = filter;
        } else {
          dataTableParams["filter"] = {};
        }
      }
      //condition for campaig layout
      // if (this.layout === "CampaignDashboardLayout") {
      //   if (this.userTeam === "district" || this.userTeam ==="school" || this.userTeam ==="teacher") {
      //     dataTableParams["campaign_id"] =
      //       this.selectedCampaignData.campaignData.id;
      //   }
      //   if(this.userTeam==="admin"){
      //     dataTableParams["campaign_id"] =
      //       this.selectedCampaignData.campaignData.id;
      //       if(this.$route.matched[0].path.substring(1)==="district"){
      //         dataTableParams["district_id"] = this.selectedCampaignData.organizationData.id;
      //       }else{
      //         dataTableParams["school_id"] = this.selectedCampaignData.organizationData.id;
      //       }
      //   }
      // }
      let PANEL = this.$route.matched[0].path.substring(1);
      if (this.layout === "CampaignDashboardLayout") {
        if (PANEL === "district" && this.userTeam === "district") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
        } else if (PANEL === "school" && this.userTeam === "school") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
        } else if (PANEL === "teacher" && this.userTeam === "teacher") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
        } else if (PANEL === "district" && (this.userTeam === "admin" ||this.userTeam === "game")) {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          dataTableParams["district_id"] =
            this.selectedCampaignData.organizationData.id;
        } else if (
          PANEL === "school" &&
          (this.userTeam === "admin" || this.userTeam === "district" || this.userTeam === "game" )
        ) {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          dataTableParams["school_id"] =
            this.selectedCampaignData.organizationData.id;
        } else if (
          PANEL === "teacher" &&
          (this.userTeam === "admin" ||
            this.userTeam === "district" ||
            this.userTeam === "school")
        ) {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          dataTableParams["teacher_id"] =
            this.selectedCampaignData.organizationData.id;
        }

        else if (
          PANEL === "teacher" &&
          (this.userTeam === "admin" ||
            this.userTeam === "game" ||
            this.userTeam === "school")
        ) {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          dataTableParams["teacher_id"] =
            this.selectedCampaignData.organizationData.id;
        }
      }
      // console.log(dataTableParams, "datatableparam");
      Axios.request_GET(
        API_ADMIN_STUDENT_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    viewPublicDonationPageHandler(item) {
      const { id, sub_domain } = item;
      const queryParam = "student=" + id;
      Helper.openPublicDonationPage(sub_domain, queryParam);
    },
    exportStudent() {
      this.btnLoading = true;
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.btnLoading = false;
      };
      let formData = {};
      formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
      let PANEL = this.$route.matched[0].path.substring(1);
      if (PANEL === "district") {
        formData["district_id"] = this.selectedCampaignData.organizationData.id;
      } else {
        formData["school_id"] = this.selectedCampaignData.organizationData.id;
      }
      if (this.dates) {
        if (this.dates.length == 2) {
          let filter = {
            start_date: moment(this.dates[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates[1]).format("MM-DD-YYYY"),
          };
          formData["filter"] = filter;
        } else {
          formData["filter"] = {};
        }
      }
      Axios.request_GET_BLOB(
        API_EXPORT_STUDENT,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    resendLink(item) {
      const { sub_domain, parent_email, id } = item;
      // console.log(sub_domain, parent_email, id);
      const queryParam = "student=" + id;
      const host = window.location.host;
      const protocol = window.location.protocol;
      const href = protocol + "//" + sub_domain + host + queryParam;
      // console.log(href, "dhdjdshdsjdshds");

      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Reset Password Link has been sent to the registered email.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["user_email"] = parent_email;
      formData["redirect_url"] = href;

      /**
       * API Call for login
       */
      Axios.request_GET(
        API_COMMON_URL.forgotPassword,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    updateVideo(item) {
      console.log(item);
      const { teacher, id } = item;
      this.toggleAddRecordModal({
        show: true,
        type: "add",
        id: teacher,
        student: id,
      });
    },
    exportStudentData() {
      this.btnLoading1 = true;
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.btnLoading1 = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.btnLoading1 = false;
      };
      let formData = {};
      formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
      let PANEL = this.$route.matched[0].path.substring(1);
      if (PANEL === "district") {
        formData["district_id"] = this.selectedCampaignData.organizationData.id;
      } else {
        formData["school_id"] = this.selectedCampaignData.organizationData.id;
      }
      Axios.request_GET_BLOB(
        API_EXPORT_STUDENT_DATA,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    getYearList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.yearList = data.year_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_GET_FISCAL_YEAR_RANGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
  created() {
    this.$root.$refs.studentManagement = this;
    this.debounceGetStudentManagementData = _.debounce(
      this.getStudentData,
      500
    );
  },
  mounted() {
    this.getStudentData();
    this.getYearList();
  },
};
</script>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 70px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 160px;
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    min-width: 1200px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu[data-v-58390903] {
    position: relative;
    bottom: -3px;
    right: -7px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}

@media (min-width: 0px) and (max-width: 600px) {
  .table-div {
  /**  margin-top: 100px; */
  }
}
</style>
